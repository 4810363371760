const InventoryTransfer = {
  path: "",
  name: "",
  redirect: "inventory-transfer",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "inventory-transfer",
      name: "Inventory Transfer",
      redirect: "inventory-transfer",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/inventoryTransfer"),
          meta: {
            requiresAuth: true,
            activeUrl: "/inventory-transfer",
            parent: "/inventory-transfer",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Inventory Transfer Detail",
          component: () => import("@/views/pages/inventoryTransfer/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/inventory-transfer",
            parent: "/inventory-transfer",

            lv: 3,
          },
        },
      ],
    },
  ],
};

const StockSetting = {
  path: "",
  name: "",
  redirect: "stock",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "stock",
      name: "Stock",
      redirect: "stock",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/stock"),
          meta: {
            requiresAuth: true,
            activeUrl: "/stock",
            parent: "/stock",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Stock Detail",
          component: () => import("@/views/pages/stock/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/stock",
            parent: "/stock",

            lv: 3,
          },
        },
      ],
    },
  ],
};
const InventorySetting = {
  path: "",
  name: "",
  redirect: "inventory",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "inventory",
      name: "Inventory Setting",
      redirect: "inventory",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Inventory"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        InventoryTransfer,
        StockSetting
      ],
    },
  ],
};
export default InventorySetting;
