// const InventoryTransfer = {
//   path: "",
//   name: "",
//   redirect: "/inventory-transfer",
//   component: {
//     render(c) {
//       return c("router-view");
//     },
//   },
//   children: [
//     {
//       path: "/inventory-transfer",
//       redirect: "/inventory-transfer",
//       component: {
//         render(c) {
//           return c("router-view");
//         },
//       },
//       name: "Inventory Transfer",
//       meta: {
//         requiresAuth: true,
//         activeUrl: "/inventory-transfer",
//         parent: "/inventory-transfer",

//         lv: 1,
//       },
//       children: [
//         {
//           path: "",
//           name: "",
//           component: () => import("@/views/pages/inventoryTransfer"),
//           meta: {
//             requiresAuth: true,
//             activeUrl: "/inventory-transfer",
//             parent: "/inventory-transfer",

//             lv: 2,
//           },
//         },
//         {
//           path: "detail/:id",
//           name: "Inventory Transfer Detail",
//           component: () => import("@/views/pages/inventoryTransfer/detail"),
//           meta: {
//             requiresAuth: true,
//             isChildren: true,
//             activeUrl: "/inventory-transfer",
//             parent: "/inventory-transfer",

//             lv: 3,
//           },
//         },
//       ],
//     },
//   ],
//   meta: { requiresAuth: true },
// };
const PurchaseOrder = {
  path: "",
  name: "",
  redirect: "/purchase-order",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/purchase-order",
      redirect: "/purchase-order",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Purchase Order",
      meta: {
        requiresAuth: true,
        activeUrl: "/purchase-order",
        parent: "/purchase-order",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/purchaseOrder"),
          meta: {
            requiresAuth: true,
            activeUrl: "/purchase-order",
            parent: "/purchase-order",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Purchase Order Detail",
          component: () => import("@/views/pages/purchaseOrder/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/purchase-order",
            parent: "/purchase-order",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
const Inventory = {
  path: "",
  name: "",
  redirect: "/inventory",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "/inventory",
      name: "Inventory",
      redirect: "/inventory",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Inventory"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },

        // InventoryTransfer,
        PurchaseOrder,
      ],
    },
  ],
};
const RequestPurchase = {
  path: "",
  name: "",
  redirect: "/request-purchase",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/request-purchase",
      name: "RQP",
      redirect: "/request-purchase",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/request-purchase",
        isChildren: true,
        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/requestPurchase/index"),
          meta: {
            requiresAuth: true,
            activeUrl: "/request-purchase",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "log/:log_id",
          name: "RQP Log",
          component: () =>
            import("@/views/pages/setting/settingPage/requestPurchase/logs"),
          meta: {
            requiresAuth: true,
            activeUrl: "/request-purchase",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "detail/:id",
          name: "RQP Detail",

          component: () =>
            import("@/views/pages/setting/settingPage/requestPurchase/detail"),
          meta: {
            requiresAuth: true,

            activeUrl: "/request-purchase",
            isChildren: true,
            lv: 3,
          },
        },
      ],
    },
  ],
};
export { PurchaseOrder, RequestPurchase };
